import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    component: resolve => require(['./view/home'], resolve)
  },
  {
    path: '/investmentServices',
    component: resolve => require(['./view/investment-services'], resolve)
  },
  {
    path: '/AIStock',
    component: resolve => require(['./view/AI-stock'], resolve)
  },
  {
    path: '/item',
    component: resolve => require(['./view/item'], resolve)
  },
  {
    path: '/new',
    component: resolve => require(['./view/new'], resolve)
  },
  {
    path: '/about',
    component: resolve => require(['./view/about'], resolve)
  },
  {
    path: '/tips',
    component: resolve => require(['./view/tips'], resolve)
  },
  {
    path: '/privacyPolicy',
    component: resolve => require(['./view/privacy-policy'], resolve)
  },
  {
    path: '/reliefPolicy',
    component: resolve => require(['./view/relief-policy'], resolve)
  },
  {
    path: '/questionnaire',
    component: resolve => require(['./view/questionnaire'], resolve)
  },
  {
    path: '/publicity',
    component: resolve => require(['./view/publicity'], resolve)
  },
  {
    path: '/art',
    component: resolve => require(['./view/art/art'], resolve)
  },
  {
    path: '/art/art1',
    component: resolve => require(['./view/art/art1'], resolve)
  },
  {
    path: '/art/art2',
    component: resolve => require(['./view/art/art2'], resolve)
  },
  {
    path: '/art/art3',
    component: resolve => require(['./view/art/art3'], resolve)
  },
  {
    path: '/art/art4',
    component: resolve => require(['./view/art/art4'], resolve)
  },
  {
    path: '/art/art5',
    component: resolve => require(['./view/art/art5'], resolve)
  },
  {
    path: '/art/art6',
    component: resolve => require(['./view/art/art6'], resolve)
  },
  {
    path: '/art/art7',
    component: resolve => require(['./view/art/art7'], resolve)
  },
  {
    path: '/art/art8',
    component: resolve => require(['./view/art/art8'], resolve)
  },
  {
    path: '/imgView',
    component: resolve => require(['./view/img-view'], resolve)
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

