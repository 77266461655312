<template>
  <article class="ks-header-ui clearfix">
    <div class="fl">
      <img src="./logo.png" @click="goLink('/')"/>
    </div>
    <ul class="nav-box">
      <li @click="goLink('/')" :class="[navCur === 0 ? 'cur' : '']">首页<i v-show="navCur === 0"></i></li>
      <li @click="goLink('/investmentServices')" :class="[navCur === 1 ? 'cur' : '']">投顾服务<i v-show="navCur === 1"></i></li>
      <li @click="goLink('/AIStock')" :class="[navCur === 2 ? 'cur' : '']">智能选股<i v-show="navCur === 2"></i></li>
      <li @click="goLink('/item')" :class="[navCur === 3 ? 'cur' : '']">投顾团队<i v-show="navCur === 3"></i></li>
      <li @click="goLink('/new')" :class="[navCur === 4 ? 'cur' : '']">投资快讯<i v-show="navCur === 4"></i></li>
      <li @click="goLink('/about')" :class="[navCur === 5 ? 'cur' : '']">关于我们<i v-show="navCur === 5"></i></li>
      <li @click="goLink('/tips')" :class="[navCur === 6 ? 'cur' : '']">投资者教育<i v-show="navCur === 6"></i></li>
    </ul>
  </article>
</template>

<script>
  export default {
    props: {
      navCur: {
        type: Number,
        default: 222
      }
    },
    methods: {
      goLink (router) {
        this.$router.push({path: router})
      }
    }
  }
</script>

<style lang="scss">
  .ks-header-ui{
    height: 90px;
    line-height: 90px;
    font-size: 18px;
    width: 1200px;
    margin: 0 auto;
    img{
      vertical-align: middle;
      cursor: pointer;
    }
    .nav-box{
      float: right;
      .cur{
        color:#081c33;
        font-weight: 500;
      }
      li{
        cursor: pointer;
        float: left;
        margin-right: 48px;
        position: relative;
        i{
          position: absolute;
          display: block;
          width: 34px;
          left: 50%;
          margin-left: -17px;
          height: 3px;
          background: #081c33;
          bottom:20px
        }
      }
      li:last-child{
        margin-right: 0;
      }
    }
  }
</style>
