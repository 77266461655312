/**
 * Created by Administrator on 2017/6/11.
 */
import Vue from 'vue'
import router from './router'
import App from './app'
import axios from 'axios'
import jyRootHeader from 'comp/header'
import jyRootFooter from 'comp/footer'
new Vue({
  el: '#app',
  render: h => h(App),
  router
})

Vue.prototype.$axios = axios
Vue.mixin({
  components: {
    jyRootHeader,
    jyRootFooter
  },
  methods: {
    setTitle (title) {
      setTimeout(() => {
        document.title = title
        var iframe = document.createElement('iframe')
        iframe.src = '/favicon.ico'
        iframe.style.visibility = 'hidden'
        iframe.style.width = '1px'
        iframe.style.height = '1px'
        iframe.onload = function () {
          setTimeout(function () {
            document.body.removeChild(iframe)
          }, 0)
        }
        document.body.appendChild(iframe)
      }, 0)
    },
    ajax (url, json, method = 'post', timeout = 25000) {
      const promise = new Promise((resolve, reject) => {
        if (!url || !json) {
          reject({status: 0, msg: `url or josn is null`})
        }
        let req = {
          url: url,
          method: method,
          timeout: timeout
        }
        let loginInfo = Utils.tool.cookie.GetCookie('loginInfo')
        if (method === 'get') {
          req.params = json
        } else {
          // req.data = qs.stringify(json)
          req.data = json
        }
        if (loginInfo) {
          req.headers = {'token': JSON.parse(loginInfo).token}
        }
        this.$axios(req).then((response) => {
          if (parseInt(response.data.code) === 0) {
            resolve(response.data.data)
          } else {
            reject(response.data.msg)
          }
        }).catch((error) => {
          reject('请求失败')
          console.error(`ajax error: ${url} ### ${error}`)
        })
      })
      return promise
    }
  }
})
