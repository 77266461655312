<template>
  <article class="ks-footer-ui clearfix" :style="{backgroundColor: bgColor}">
    <div class="footer-box">
      <ul class="nav-box clearfix">
        <li @click="goLink('/about')">公司介绍</li>
        <li @click="goLink('/about')">组织架构</li>
        <li @click="goLink('/privacyPolicy')">隐私保护</li>
        <li @click="goLink('/reliefPolicy')">免责声明</li>
        <li @click="goLink('/publicity')">投顾公示</li>
        <li @click="goLink('/questionnaire')">风险评测</li>
      </ul>
      <ul class="contact-box clearfix">
        <li class="fl">
          <i class="icon-phone fl"></i>021-61208827
        </li>
        <li class="tr fr">
          <i class="icon-email fl"></i>ks_ia@kaishizq.com
        </li>
      </ul>
      <p class="footer-tx">产品历史业绩不预示其未来表现，市场有风险，投资需谨慎</p>
      <p class="footer-tx">Copyright © {{year}} 上海凯石证券投资咨询有限公司  <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2020029841号-1</a><span @click="goLink('/imgView')">证券投资咨询牌照</span></p>
    </div>
  </article>
</template>

<script>
  export default {
    props: {
      bgColor: {
        type: String,
        default: '#f5f5f5'
      }
    },
    data () {
      return {
        year: new Date().getFullYear()
      }
    },
    methods: {
      goLink (router) {
        this.$router.push({path: router})
      }
    }
  }
</script>

<style lang="scss">
  .ks-footer-ui{
    height: 326px;
    color:#a0a0a0;
    min-width: 1200px;
    .footer-box{
      width: 1200px;
      margin: 0 auto;
      .nav-box{
        padding: 30px 0;
        font-size: 18px;
        border-bottom: 1px solid #dcdcdc;
        li{
          float: left;
          width: 200px;
          text-align: center;
          cursor: pointer;
        }
      }
      .contact-box{
        font-size: 40px;
        padding: 40px 0;
        li{
          height: 70px;
          line-height: 70px;
          width: 495px;
          i{
            margin-right: 25px;
          }
        }
        .tr{
          text-align: right;
        }
      }
      .footer-tx{
        text-align: center;
        font-size: 18px;
        margin-top: 10px;
        a {
          color:#a0a0a0;
          text-decoration: none;
        }
        span{
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }
  }
</style>
